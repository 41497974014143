import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["platformCheckbox", "shapeSelect", "dualFundedMessage"];

  connect() {
    this.toggleOptions();
    this.checkDualFundedMessage();
  }

  toggleOptions() {
    const isChecked = this.platformCheckboxTarget.checked;

    const options = isChecked
      ? [
          ["LND/BOS Dual: 2 Nodes", "2"],
          ["LND/BOS Triangle: 3 Nodes", "3"],
          ["LND/BOS Square: 4 Nodes", "4"],
          ["LND/BOS Pentagon: 5 Nodes", "5"],
        ]
      : [
          ["Triangle: 3 Nodes < Recommended", "3"],
          ["Square: 4 Nodes", "4"],
          ["Pentagon: 5 Nodes", "5"],
        ];

    this.updateSelectOptions(options);
    this.checkDualFundedMessage();
  }

  updateSelectOptions(options) {
    const currentValue = this.shapeSelectTarget.dataset.currentParticipantValue;
    this.shapeSelectTarget.innerHTML = "";
    options.forEach((option) => {
      const opt = document.createElement("option");
      opt.value = option[1];
      opt.textContent = option[0];
      if (opt.value === currentValue) {
        opt.selected = true;
      }
      this.shapeSelectTarget.appendChild(opt);
    });
  }

  checkDualFundedMessage() {
    const isChecked = this.platformCheckboxTarget.checked;
    const messageElement = this.dualFundedMessageTarget;

    if (isChecked) {
      messageElement.classList.remove("hidden");
    } else {
      messageElement.classList.add("hidden");
    }
  }
}
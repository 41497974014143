import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["days", "price", "usd-rate-per-sat", "usd"]

  initialize() {
    this.satPerDay = parseFloat(this.data.get("sat-per-day"));
    this.usdRatePerSat = parseFloat(this.data.get("usd-rate-per-sat"));
    this.updateValue()
  }

  updateValue() {
    const enteredValue = parseFloat(this.daysTarget.value);

    // If enteredValue is NaN (because of an empty input or invalid value), set a default or display a message
    if (isNaN(enteredValue)) {
      this.priceTarget.innerText = "";
      this.usdTarget.innerText = "";
      return; // Exit the function early
    }

    // Calculate total price
    const totalPrice = enteredValue * this.satPerDay;
    this.priceTarget.innerText = this.formatNumberWithCommas(totalPrice.toFixed(0));
    const calculatedUsd = totalPrice * this.usdRatePerSat;
    this.usdTarget.innerText = this.formatNumberWithCommas(calculatedUsd.toFixed(2));
  }

  // Helper function to format number with thousand delimiters
  formatNumberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import consumer from "channels/consumer";
// import "chartkick/chart.js";
import "stylesheets/application";
// require("trix");
// require("@rails/actiontext");

Rails.start();
ActiveStorage.start();
Turbolinks.start();

// Setup Stimulus
const application = Application.start();
const context = require.context("controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

// Make the consumer globally available for your Stimulus controllers
application.consumer = consumer;

// Initialize StimulusReflex with the Stimulus application
StimulusReflex.initialize(application);

// hotfix for Safari NSURLSession WebSocket bug
const originalWebSocketClose = WebSocket.prototype.close;
WebSocket.prototype.close = function () {
  if (this.readyState != WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments);
  }
};

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["section", "checkbox"]

  toggleSection(event) {
    const sectionId = event.target.dataset.section
    const section = this.sectionTargets.find(s => s.dataset.sectionId === sectionId)

    if (section) {
      section.classList.toggle("hidden", !event.target.checked)
    }
  }
}

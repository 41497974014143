import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["description", "counter"]

  initialize() {
    this.updateCounter()
  }

  updateCounter() {
    const body = this.descriptionTarget.value
    const maxCharacters = parseInt(this.counterTarget.dataset.maxCharacters)
    const remainingCharacters = maxCharacters - body.length
    this.counterTarget.innerText = remainingCharacters

    if (remainingCharacters < 0) {
      this.counterTarget.classList.add("text-red-600")
    } else {
      this.counterTarget.classList.remove("text-red-600")
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sats", "calculated", "usd-rate-per-sat", "usd"]

  initialize() {
    this.latestCreditValue = parseFloat(this.data.get("latest-credit-value"));
    this.usdRatePerSat = parseFloat(this.data.get("usd-rate-per-sat"));
    this.updateValue()
  }

  updateValue() {
    const enteredValue = parseFloat(this.satsTarget.value);

    if (isNaN(enteredValue)) {
      this.calculatedTarget.innerText = "";
      this.usdTarget.innerText = "";
      return;
    }

    // Calculate total price
    const totalPrice = enteredValue * this.latestCreditValue;
    this.calculatedTarget.innerText = this.formatNumberWithCommas(totalPrice.toFixed(0));
    const calculatedUsd = enteredValue * this.usdRatePerSat;
    this.usdTarget.innerText = this.formatNumberWithCommas(calculatedUsd.toFixed(2));
  }

  // Helper function to format number with thousand delimiters
  formatNumberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

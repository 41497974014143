import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.transactionId = this.data.get("id")
    this.consumer = this.application.consumer.subscriptions.create({
      channel: "CreditTransactionChannel",
      id: this.transactionId
    }, {
      received: this.cableReceived.bind(this)
    })
  }

  disconnect() {
    this.consumer.unsubscribe()
  }

  cableReceived(data) {
    if (data.reload) {
      this.showNotification();
      setTimeout(() => location.reload(), 3000);  // Reload after 3 seconds
    }
  }

  showNotification() {
    const notificationHolder = document.getElementById('notification-holder');
    const notificationHTML = '<p class="bg-amber-100 dark:bg-amber-900 mt-2 mb-6 px-6 py-4 rounded">This page will be reloaded shortly to reflect updated information...</p>';
    notificationHolder.innerHTML = notificationHTML;
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "millions", "btc"];

  connect() {
    this.ensureNumberFormat();
}

  ensureNumberFormat() {
    this.inputTarget.addEventListener("keyup", this.updateSizeValues.bind(this));
    this.inputTarget.form.addEventListener("submit", this.handleSubmit.bind(this));
  }

  handleSubmit(event) {
    event.preventDefault();
    this.inputTarget.form.submit();
  }

  updateSizeValues() {
    const rawValue = parseFloat(this.inputTarget.value) || 0;

    const millions = this.formatNumber((rawValue / 1000000).toFixed(8));
    const btc = this.formatNumber((rawValue / 100000000).toFixed(8));

    this.millionsTarget.textContent = `${millions}M SAT`;
    this.btcTarget.textContent = `${btc} BTC`;
  }

  formatNumber(num) {
    const str = num.toString();
    if (str.indexOf('.') !== -1) {
        return str.replace(/\.?0+$/, "");
    }
    return str;
  }

}

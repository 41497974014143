import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["display"];

  connect() {
    this.load();
  }

  load() {
    let endTime = new Date(this.data.get("end-time"));
    this.updateCountdown(endTime);
  }

  updateCountdown(endTime) {
    let now = new Date();
    let distance = endTime - now;

    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Pad hours, minutes, and seconds with leading zeros if needed
    let hoursFormatted = String(hours).padStart(2, '0');
    let minutesFormatted = String(minutes).padStart(2, '0');
    let secondsFormatted = String(seconds).padStart(2, '0');

    let timeString = "";
    if (days > 0) timeString += `${days}d `;
    if (hours > 0) timeString += `${hours}:`;
    timeString += `${minutesFormatted}:${secondsFormatted}`;

    this.displayTarget.textContent = timeString;

    if (distance < 0) {
      clearInterval(this.interval);
      this.displayTarget.textContent = "Expired";
    } else {
      if (!this.interval) {
        this.interval = setInterval(() => this.updateCountdown(endTime), 1000);
      }
    }
  }

  disconnect() {
    clearInterval(this.interval);
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["clock", "status", "end-time", "payButton"];

  connect() {
    this.load();
  }

  load() {
    let endTime = new Date(this.data.get("end-time"));
    this.updateCountdown(endTime);
  }

  updateCountdown(endTime) {
    let now = new Date();
    let distance = endTime - now;

    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    this.clockTarget.textContent = `${minutes} min ${seconds} sec`;

    if (distance < 0) {
      clearInterval(this.interval);
      this.clockTarget.textContent = "Expired";
      this.statusTarget.textContent = "Expired";
      this.payButtonTarget.style.display = "none";
    } else {
      if (!this.interval) {
        this.interval = setInterval(() => this.updateCountdown(endTime), 1000);
      }
    }
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
